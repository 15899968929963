<template>
  <div  class="minWidth1000">
    <el-form inline label-width="100px">
      <el-form-item label="设备类型">
        <el-select clearable v-model="searchForm.deviceType">
          <el-option label="摄像头设备" value="0"></el-option>
          <el-option label="实验设备" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="设备串号">
        <el-input v-model="searchForm.deviceNumber" placeholder="设备串号"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSearch" icon="el-icon-search">查询</el-button>
      </el-form-item>
    </el-form>

    <el-table stripe size="mini" border v-loading="loading" :data="tableData">
      <el-table-column label="设备名称" prop="deviceName"></el-table-column>
      <el-table-column label="设备类型" prop="deviceType"></el-table-column>
      <el-table-column label="所属企业" prop="corpName"></el-table-column>
      <el-table-column label="入库时间" prop="deviceInTime"></el-table-column>
      <el-table-column label="设备厂家" prop="deviceSupplier"></el-table-column>
      <el-table-column label="设备型号" prop="deviceModel"></el-table-column>
      <el-table-column label="设备串号" prop="deviceNumber"></el-table-column>
      <el-table-column label="设备检测时间" prop="deviceTestTime"></el-table-column>
      <el-table-column label="操作">
        <template v-slot="{ row }">
          <el-button type="text" @click="toDetail(row.deviceId)">查看</el-button>
          <!-- <el-button type="text" @click="showForm(row)">编辑</el-button>
          <el-button type="text" @click="deleteRow(row.materialId)" class="del-btn">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page.sync="page"
      background
      layout="total, prev, pager, next"
      :total="total"
      @current-change="loadData">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'DevicesManage',
  data () {
    return {
      tableData: [],
      page: 1,
      total: 0,
      loading: false,
      searchForm: {
        deviceType: '',
        deviceNumber: ''
      }
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    loadData () {
      this.$axios.get(this.$apis.system.selectDeviceByList, {
        params: {
          page: this.page,
          ...this.searchForm
        }
      }).then(res => {
        this.tableData = res.list
        this.total = res.total
      })
    },
    onSearch () {
      this.page = 1
      this.loadData()
    },
    toDetail (deviceId) {
      this.$router.push('/product/playervideo?deviceId=' + deviceId)
    },
    deleteRow (materialId) {
      this.$confirm('确认删除该材料, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$apis.material.deleteMaterial, {
          materialId
        }).then(() => {
          this.loadData()
        })
      })
    }
  }
}
</script>

<style scoped lang='scss'>

</style>
